<template>
  <div>
    <div
      v-if="current_client
        && (!($flipper.enabled($FT.HORIZONTAL_NAV_FOR_USERS) && $flipper.enabled($FT.HORIZONTAL_NAV))
          || (current_profile && current_profile.type !== PROFILE_TYPES.EMPLOYEE))"
      class="p-3 cursor-pointer"
      data-test="dropdown-general-user"
      @click="toggle">
      <div v-if="current_profile" class="flex items-center">
        <Avatar
          size="30"
          :src="current_profile.attributes.avatar_url"
          :username="current_profile.attributes.name"/>
      </div>
    </div>
    <div
      v-else
      class="p-3 cursor-pointer"
      data-test="dropdown-general-user"
      @click="toggle">
      <div v-if="current_profile" class="flex items-center">
        <Avatar
          size="20"
          :src="current_profile.attributes.avatar_url"
          :username="current_profile.attributes.name"/>
      </div>
    </div>
    <PMenu id="overlay_menu" ref="menu" :model="items" :popup="true">
      <template #item="{ item, props }">
        <router-link v-if="item.action === 'profile'" :to="{ name: item.route }">
          <div :data-test="item.datatest" v-bind="props.action">
            {{ $t(`${item.label}`) }}
          </div>
        </router-link>
        <div v-else-if="item.action === 'selectTenant' && has_multiple_profiles">
          <a
            v-bind="props.action"
            data-test="select-company"
            @click="selectTenant">
            <div v-ripple class="relative overflow-hidden w-full p-link flex items-center border-noround">
              <PAvatar
                v-if="current_client"
                class="mr-3"
                :image="current_client.attributes.picture_logo_url_m"
                size="large"/>
              <span class="font-bold"> {{ $t('navbar.topnavbar.selectTeam') }}</span>
            </div>
          </a>
        </div>
        <a
          v-else-if="item.action === 'logout'"
          v-bind="props.action"
          data-test="logout"
          @click="emitter.$emit('logout')">
          <div>
            {{ $t('navbar.topnavbar.logout') }}
          </div>
        </a>
        <a
          v-if="item.action === 'stopimpersonation'
            && current_is_impersonation
            && current_profile_true.type === PROFILE_TYPES.SUPERVISOR"
          v-bind="props.action"
          @click="emitter.$emit('stopImpersonate')">
          {{ $t('contact.actions.impersonateStop') }}
        </a>
      </template>
    </PMenu>
  </div>
</template>

<script>
import { PROFILE_TYPES, Employee } from '@/app/data/model_constants';
import { ROUTES_EMPLOYEES, ROUTES_CUSTOMERS, ROUTES_PARTNERS } from '@/app/data/route_constants';
import SelectTenant from '@/app/shared_components/SelectTenant.vue';
import { mapGetters } from 'vuex';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'ProfileDropdown',

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      PROFILE_TYPES,
      ROUTES_EMPLOYEES,
      ROUTES_CUSTOMERS,
      ROUTES_PARTNERS,
      Employee,
    };
  },

  computed: {
    ...mapGetters([
      'current_is_impersonation',
      'current_profile',
      'current_client',
      'current_profile_true',
      'current_project',
    ]),

    routeProfileName() {
      switch (this.current_profile.type) {
      case PROFILE_TYPES.CUSTOMER:
        return ROUTES_CUSTOMERS.SETTINGS_PROFILE;
      case PROFILE_TYPES.EMPLOYEE:
        return ROUTES_EMPLOYEES.SETTINGS_PROFILE;
      case PROFILE_TYPES.PARTNER:
        return ROUTES_PARTNERS.SETTINGS_PROFILE;
      default:
        return '';
      }
    },

    routeProfileParams() {
      // route for customers is namespaced with project_id
      return this.current_profile.type === PROFILE_TYPES.CUSTOMER
        ? { project_id: _.get(this.current_project, 'id', 0) } // dummy value prevents vue router warning
        : {};
    },

    items() {
      return [
        {
          label: 'navbar.topnavbar.selectTeam',
          action: 'selectTenant'
        },
        {
          label: 'navbar.topnavbar.profile',
          action: 'profile',
          route: this.routeProfileName,
          params: this.routeProfileParams,
          datatest: 'profile'
        },
        {
          label: 'navbar.topnavbar.logout',
          action: 'logout'
        },
        {
          label: 'contact.actions.impersonateStop',
          action: 'stopimpersonation'
        },
      ]
    },

    has_multiple_profiles() {
      if (this.current_is_impersonation && this.current_profile_true.type === PROFILE_TYPES.EMPLOYEE) return false;
      return this.current_profile.attributes.profiles_count > 1;
    },
  },

  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    selectTenant() {
      this.$buefy.modal.open({

        component: SelectTenant,
      });
    },
  },
};
</script>
