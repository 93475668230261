<template>
  <div v-if="is_helpcenter_available" support-tag="helpcenter-general">
    <a
      v-if="(current_client && !($flipper.enabled($FT.HORIZONTAL_NAV_FOR_USERS) && $flipper.enabled($FT.HORIZONTAL_NAV)))
        || (current_profile && current_profile.type !== PROFILE_TYPES.EMPLOYEE)"
      class="topbar-item" style="cursor: pointer;"
      @click="toggleSidebar">
      <span
        class="icon is-large" :class=" (mq.isMobile || mq.isTablet) ? 'has-text-white' : 'has-text-grey'">
        <span
          class="badge has-badge-rounded"
          :class="(mq.isMobile || mq.isTablet) ? 'ziggu-navbar-item' : 'has-text-grey'">
          <i class="far fa-circle-question fa-lg"/>
        </span>
      </span>
    </a>
    <div v-else class="w-10 flex justify-center">
      <a @click="toggleSidebar">
        <FontAwesomeIcon class="has-text-white" :icon="['far', 'circle-question']" size="lg" />
      </a>
    </div>

    <Sidebar
      id="sidebar-helpcenter"
      class="w-full md:w-[30rem] lg:w-[40rem] mb-0"
      position="right"
      :pt="{
        mask: 'sidebar_right_vertical_navigation',
        content: 'p-0 pt-0 h-full w-full grow overflow-y-auto'
      }"
      :visible="is_visible"
      @update:visible="toggleSidebar">
      <template #header>
        <span class="inline-flex items-center gap-2">
          <span class="font-semibold text-2xl">
            {{ $t('navbar.topnavbar.helpcenter') }}
          </span>
          <div
            class="hover-primary p-2"
            @click="openFullScreen">
            <FontAwesomeIcon icon="expand-wide" size="lg"/>
          </div>
        </span>
      </template>
      <iframe
        v-if="initialized"
        id="helpcenter"
        :src="url"
        style="width: 100%; height: 100%;"/>
    </Sidebar>
  </div>
</template>

<script>
import { PROFILE_TYPES } from '@/app/data/model_constants';
import { mapGetters, mapActions } from 'vuex';
import { useMqService } from '@/plugins/mq';

const SIDEBAR_TYPE = 'help';

export default {
  name: 'HelpSidebar',
  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      initialized: false, // lazy loading (once) of iframe upon toggle sidebar
      PROFILE_TYPES,
      url: null,
    };
  },

  computed: {
    ...mapGetters([
      'current_client',
      'current_profile',
      'current_project',
      'current_help_center_base_url',
      'sidebar_type',
    ]),

    is_visible() {
      return this.sidebar_type === SIDEBAR_TYPE;
    },

    is_helpcenter_available() {
      const profile_types_helpcenter = [PROFILE_TYPES.EMPLOYEE, PROFILE_TYPES.SUPERVISOR];
      if (!_.isEmpty(this.current_client)) profile_types_helpcenter.push(PROFILE_TYPES.PARTNER);

      return profile_types_helpcenter.includes(this.current_profile.type);
    },

    url_initial() {
      return `${this.current_help_center_base_url}/index.html`;
    },
  },

  created() {
    this.url = this.url_initial;
    this.$root.emitter.$on('showHelpSidebarPage', this.showHelpSidebarPage);
  },

  methods: {
    ...mapActions([
      'SHOW_SIDEBAR',
    ]),

    toggleSidebar() {
      if (this.sidebar_type === SIDEBAR_TYPE) {
        this.SHOW_SIDEBAR(null);
        this.hide_intercom(false);
      } else {
        this.initialized = true;
        if (!this.mq.isDesktop) this.hide_intercom(true);
        this.SHOW_SIDEBAR(SIDEBAR_TYPE);
        window.addEventListener('resize', _.debounce(() => {
        }, 100));
      }
    },

    hide_intercom(hide) {
      window.Intercom('update', { hide_default_launcher: hide });
    },

    showHelpSidebarPage(url_relative) {
      this.url = `${this.current_help_center_base_url}/${url_relative}`;
      this.toggleSidebar(SIDEBAR_TYPE);
    },

    openFullScreen() {
      let url;
      try {
        // obtaining iframe URL only works in case of same-origin URL (port included!), otherwise DOMException is thrown
        url = document.getElementById('helpcenter').contentWindow.location.href;
      } catch {
        // fallback to help center home (applicable as well in local mode, as port of app and help differ)
        url = this.url_initial;
      }
      window.open(url, '_blank', 'noopener=false');
    },
  },
};
</script>
