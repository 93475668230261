<template>
  <b-modal
    v-model="show_modal"
    :can-cancel="false"
    :on-cancel="() => $emit('close')">
    <SurveyIntroModal
      v-if="show_intro_modal"
      :survey_type="survey_type_current_survey"
      @close="show_intro_modal = false"/>
    <Modal
      v-else-if="show_surveys_modal && question_currently_shown"
      :data-resource-id="question_currently_shown.attributes.survey_id"
      :data-resource-type="RECORD_TYPE.SURVEY"
      data-test="survey-popup-modal"
      :is_cancel="false"
      :is_confirmed="is_confirmed"
      :is_show_header="false"
      :is_small="false"
      :prevent_enter_shortcut="true"
      @actionPrimary="updateQuestion"
      @actionSecondary="updateQuestion(SurveyQuestion.STATUS_ANSWER_LATER)">
      <template #content>
        <SurveyForm
          :question_number="question_currently_shown_number"
          :question_type="question_currently_shown.attributes.question_type"
          :questions="survey_type_current_survey.attributes.questions_count"
          style="width: 100%;"
          :title="question_currently_shown.attributes.title"
          @feedbackUpdated="feedbackUpdated"
          @scoreSelected="scoreSelected"/>
      </template>
      <template #ButtonPrimary>
        {{ $t('survey.actions.submit') }}
      </template>
      <template #ButtonSecondary>
        {{ $t('survey.labels.answer_later') }}
      </template>
      <template v-if="current_question_optional"
                #content-footer>
        <ButtonTertiary
          data-test="modal-confirm-tertiary"
          @onClick="() => updateQuestion(SurveyQuestion.STATUS_NO_OPINION)">
          {{ $t('survey.labels.no_opinion') }}
        </ButtonTertiary>
      </template>
    </Modal>
    <ThankYouModal
      v-else-if="show_thank_you_modal"
      :client_name="current_client.attributes.name"
      @close="$emit('close')"/>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '@/app/shared_components/modals/TheModal.vue';
import SurveyForm from '@/app/shared_components/surveys/SurveyForm.vue';
import { RECORD_TYPE, SurveyQuestion } from '@/app/data/model_constants';
import activity_notification_open_mixin from '@/app/util/activity_notification_open_mixin';
import ThankYouModal from '@/app/shared_components/surveys/TheThankYouModal.vue';
import SurveyIntroModal from '@/app/shared_components/surveys/SurveyIntroModal.vue';
import { isEmptyHTML } from '@/app/util/editor/editor';

export default {
  name: 'SurveysPopup',
  components: {
    Modal, SurveyForm, ThankYouModal, SurveyIntroModal,
  },
  mixins: [
    activity_notification_open_mixin,
  ],
  props: {
    project_id: { type: String, required: true },
    survey_id: { type: String, default: null },
  },

  emits: ['close'],

  data() {
    return {
      is_confirmed: false,
      show_surveys_modal: false,
      show_thank_you_modal: false,
      show_intro_modal: false,
      questions_queue: [],
      surveys: [],
      RECORD_TYPE,
      SurveyQuestion,
    };
  },

  computed: {
    ...mapGetters([
      'current_client',
      'survey_questions',
      'current_is_impersonation',
      'survey_types',
    ]),

    show_modal: {
      set() { return true; },
      get() { return this.show_intro_modal || this.show_surveys_modal || this.show_thank_you_modal; },
    },

    question_currently_shown() { return _.first(this.questions_queue); },
    survey_currently_shown() {
      if (this.question_currently_shown) {
        return _.find(this.surveys, ['id', this.question_currently_shown.attributes.survey_id]);
      }
      return undefined;
    },
    survey_type_current_survey() {
      if (this.survey_currently_shown) {
        return _.find(this.survey_types, ['id', this.survey_currently_shown.attributes.survey_type_id]);
      }
      return undefined;
    },
    current_question_optional() {
      if (this.survey_type_current_survey) {
        return !this.survey_type_current_survey.attributes.obligatory;
      }
      return undefined;
    },
    question_currently_shown_number() {
      if (this.survey_type_current_survey) {
        return this.survey_type_current_survey.attributes.questions_count
            - _.filter(this.questions_queue, ['attributes.survey_type_id', this.survey_type_current_survey.id]).length + 1;
      }
      return undefined
    },
  },

  watch: {
    'survey_currently_shown.id': {
      immediate: true,
      handler() {
        if (this.survey_currently_shown && !isEmptyHTML(this.survey_type_current_survey.attributes.description_html)) {
          this.show_intro_modal = true;
        }
      },
    },
  },

  async created() {
    this.surveys = await this.FETCH_SURVEYS({ project_id: this.project_id, answered: false });
    if (_.isEmpty(this.surveys) && !this.survey_id) {
      this.$emit('close');
      return;
    }
    this.setUpSurveysQueue();
    this.show_surveys_modal = true;
    this.openSurveyActivitiesImplicit(this.surveys);
  },

  methods: {
    ...mapActions(['FETCH_SURVEYS', 'UPDATE_SURVEY_QUESTION']),

    setUpSurveysQueue() {
      const unresponded = [SurveyQuestion.STATUS_UNANSWERED, SurveyQuestion.STATUS_ANSWER_LATER];
      const survey_ids = this.survey_id ? [this.survey_id] : _.map(this.surveys, 'id');
      const questions = _.chain(this.survey_questions)
        .filter((q) => survey_ids.includes(q.attributes.survey_id))
        .filter((q) => unresponded.includes(q.attributes.status))
        .groupBy('attributes.survey_type_id')
        .map((qs) => _.chain(qs)
          .groupBy('attributes.survey_id')
          .values()
          .first()
          .value())
        .flatten()
        .value();

      _.each(questions, (survey_question) => {
        this.questions_queue.push({
          id: survey_question.id,
          attributes: {
            title: survey_question.attributes.title,
            question_type: survey_question.attributes.question_type,
            survey_id: survey_question.attributes.survey_id,
            survey_type_id: survey_question.attributes.survey_type_id,
            survey_answer_attributes: {},
          },
        });
      });
    },

    feedbackUpdated(val) {
      this.question_currently_shown.attributes.survey_answer_attributes.answer = val;
    },

    scoreSelected(val) {
      this.question_currently_shown.attributes.survey_answer_attributes.score = val;
      this.is_confirmed = true;
    },

    async updateQuestion(status = SurveyQuestion.STATUS_ANSWERED) {
      const is_answered = status === SurveyQuestion.STATUS_ANSWERED;
      const survey_ids = _.chain(this.surveys)
        .filter(['attributes.survey_type_id', this.question_currently_shown.attributes.survey_type_id])
        .map('id')
        .value();
      const survey_questions = _.chain(this.survey_questions)
        .filter((q) => survey_ids.includes(q.attributes.survey_id))
        .filter((q) => q.attributes.title === this.question_currently_shown.attributes.title)
        .value();

      await Promise.all(_.map(survey_questions, async (question) => {
        const record = _.cloneDeep(question);
        record.attributes.status = status;
        if (is_answered) {
          const { score, answer } = this.question_currently_shown.attributes.survey_answer_attributes;
          record.attributes.survey_answer_attributes = { score, answer };
        }
        await this.UPDATE_SURVEY_QUESTION({ record });
      }));

      if (status !== SurveyQuestion.STATUS_ANSWER_LATER) this.openSurveyNotificationsExplicit(survey_questions);
      if (is_answered) this.show_thank_you_modal = true;
      this.questions_queue = status !== SurveyQuestion.STATUS_ANSWER_LATER
        ? _.filter(this.questions_queue, (e) => e.id !== this.question_currently_shown.id)
        : [];
      if (_.isEmpty(this.questions_queue) && !this.show_thank_you_modal) this.$emit('close');
      this.is_confirmed = false;
    },
  },
};
</script>
