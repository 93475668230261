<template>
  <nav
    id="primarybar"
    class="is-flex is-align-items-center px-5 has-background-primary has-text-colour-white"
    data-test="navbar-desktop-topbar">
    <div class="is-flex-grow-1 is-flex is-align-items-center">

      <ul v-if="mq.isDesktop" class="is-flex">
        <router-link
          :to="{ name: ROUTES_EMPLOYEES.CLIENT_DASHBOARD }">
          <img class="mr-5" :src="ziggu_logo" style="height: 20px;">
        </router-link>
        <NavbarItemHorizontal
          v-for="(item, idx) in NAVBAR_ITEMS_EMPLOYEES_CLIENT"
          :key="idx"
          class="mr-5"
          :is_primary_navbar="true"
          :item="item"
          :params="item.route_params"
          @openProjectSideBar="show_project_sidebar = !show_project_sidebar"/>
      </ul>
      <div v-else class="is-flex self-center cursor-pointer text-white hover:text-slate-100">
        <div
          class="is-flex is-align-items-center"
          @click="toggle">
          <FontAwesomeIcon
            class="self-center"
            :icon="['far','bars']"
            size="lg"/>
        </div>

        <PMenu id="overlay_menu" ref="menu" :model="NAVBAR_ITEMS_EMPLOYEES_CLIENT" :popup="true">
          <template #item="{ item, props }">
            <NavbarItemHorizontal
              v-bind="props.action"
              :item="item"
              :params="item.route_params"
              @openProjectSideBar="show_project_sidebar = !show_project_sidebar"/>
          </template>
        </PMenu>
        <router-link
          class="ml-3"
          :to="{ name: ROUTES_EMPLOYEES.CLIENT_DASHBOARD }">
          <img class="mr-5" :src="ziggu_logo" style="height: 20px;">
        </router-link>
      </div>
    </div>

    <div
      v-if="current_client.attributes.ooo && $flipper.enabled($FT.HORIZONTAL_NAV) && !mq.isMobile"
      class="notification is-danger mb-0 py-1 px-2 has-text-weight-bold cursor-pointer"
      @click="$router.push({ name: ROUTES_EMPLOYEES.CLIENT_COMPANY, hash: '#ooo' })">
      {{ $t('client.labels.warningooo') }}
    </div>

    <ul class="is-flex is-align-items-center">
      <HelpSidebar/>
      <TheMarketplaceNavbar/>
      <TheClientSettingsNavbar/>
      <NotificationSidebar/>
      <ProfileDropdown/>
    </ul>

    <TheProjectSideBar
      :show_project_sidebar="show_project_sidebar"
      @closeProjectSidebar="show_project_sidebar = false"/>
  </nav>
</template>

<script>

import TheMarketplaceNavbar from '@/app/shared_components/navbar/TheMarketplaceNavbar.vue';
import TheClientSettingsNavbar from '@/app/shared_components/navbar/TheClientSettingsNavbar.vue';
import HelpSidebar from '@/app/shared_components/navbar/TheHelpSidebar.vue';
import NotificationSidebar from '@/app/shared_components/navbar/notifications/TheNotificationsSidebar.vue';
import ProfileDropdown from '@/app/shared_components/navbar/TheProfileDropdown.vue';
import { useMqService } from '@/plugins/mq';
import { NAVBAR_ITEMS_EMPLOYEES_CLIENT } from '@/app/data/navbar_constants';
import NavbarItemHorizontal from '@/app/shared_components/navbar/NavbarItemHorizontal.vue';
import TheProjectSideBar from '@/app/_employees/components/sidebars/TheProjectSideBar.vue';
import ziggu_logo from '@/assets/images/ziggu_logo_neg.png';
import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import { mapGetters } from 'vuex';

export default {
  name: 'TheNavbarDesktopClientHorizontal',
  components: {
    HelpSidebar,
    NotificationSidebar,
    ProfileDropdown,
    NavbarItemHorizontal,
    TheMarketplaceNavbar,
    TheClientSettingsNavbar,
    TheProjectSideBar
  },

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      ziggu_logo,
      show_project_sidebar: false,
      NAVBAR_ITEMS_EMPLOYEES_CLIENT,
    };
  },

  computed: {
    ...mapGetters(['current_client']),
    ROUTES_EMPLOYEES() {
      return ROUTES_EMPLOYEES
    }
  },

  created() {
    if (this.$flipper.enabled(this.$FT.CLIENT_OVERVIEW)) {
      this.NAVBAR_ITEMS_EMPLOYEES_CLIENT = this.NAVBAR_ITEMS_EMPLOYEES_CLIENT.filter((item) => item.name !== ROUTES_EMPLOYEES.CLIENT_TICKETS);
    }
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  }
};
</script>
