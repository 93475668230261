<template>

  <div
    class="button has-text-weight-semibold is-primary is-light"
    @click="is_show_dialog = true">
    <div class="icon">
      <FontAwesomeIcon icon="stars"/>
    </div>
    <span
      v-if="$slots.default"
      class="has-text-weight-semibold">
      <slot/>
    </span>
    <span v-else class="has-text-weight-semibold">
      {{ $t(`upsell.${feature}.cta`) }}
    </span>
    <PrimeDialog v-model:visible="is_show_dialog" :header="$t(`upsell.${feature}.title`)" modal>
      <LiteYouTubeEmbed
        :id="$t(`upsell.${feature}.youtube_id`)"
        ref="youtube"
        title="video"/>
      <div class="px-2 py-1">
        <div class="mt-3" v-html="$t(`upsell.${feature}.description`)"/>
      </div>
      <template #footer>
        <ButtonPrimary @onClick="openIntercom">
          {{ $t('upsell.contact_us') }}
        </ButtonPrimary>
      </template>
    </PrimeDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LiteYouTubeEmbed from 'vue-lite-youtube-embed';
import 'vue-lite-youtube-embed/style.css';

export default {
  name: 'ButtonUpsell',
  components: { LiteYouTubeEmbed },
  props: {
    feature: { type: String, required: true },
  },
  emits: ['usell-close'],
  data() {
    return {
      is_show_dialog: false,
    };
  },

  computed: {
    ...mapGetters(['current_profile']),
  },

  methods: {
    openIntercom() {
      const message = this.$t(`upsell.${this.feature}.intercom`, { name: this.current_profile.attributes.name });
      window.Intercom('showNewMessage', message);
      this.$parent.$parent.close();
      this.$emit('usellClose');
    },
  },
};
</script>
