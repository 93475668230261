<template>
  <Modal
    data-test="project-modal"
    :has_parent="false"
    :is_small="false"
    :prevent_enter_shortcut="true"
    @actionCancel="$parent.$parent.close"
    @actionPrimary="onSubmit"
    @actionSecondary="onBack">
    <template #title>
      {{ modalTitle }}
    </template>
    <template v-if="current_step === 2" #subtitle>
      {{ $t('project.labels.modalStep2SubTitle') }}
    </template>
    <template v-if="current_step === 1" #content>
      <b-field :label="`${$t('project.labels.projectName')}`">
        <div class="field w-auto md:w-4/6 lg:w-3/6">
          <input
            v-model.trim="v$.project.attributes.name.$model"
            class="input"
            :class="{ 'is-danger': v$.project.attributes.name.$error }"
            data-test="questionlist-name-input"
            type="text">
          <div v-if="v$.project.attributes.name.$error">
            <p v-if="v$.project.attributes.name.required.$invalid" class="help is-danger">
              {{ $t('project.validation.needName') }}
            </p>
            <p v-if="v$.project.attributes.name.uniqueName.$invalid" class="help is-danger">
              {{ $t('validation.nameAlreadyTaken') }}
            </p>
          </div>
        </div>
      </b-field>
      <b-switch
        v-if="canAddSUPs && current_client.attributes.ft_skip_customer"
        v-model="project.attributes.skip_customer"
        class="mb-6"
        @change="project_temp_id = null">
        {{ $t('project.labels.isTemplateProject') }}
      </b-switch>
      <div v-if="canAddSUPs && current_client.attributes.ft_skip_customer && !project.attributes.skip_customer" class="mb-5">
        <b-field :label="$t('project.labels.copyFromTemplate')">
          <SelectProject
            :projects="projects_template"
            @changeSelect="project => project_temp_id = $_.get(project, 'id', null)"/>
        </b-field>
        <section v-if="project_temp_id" class="ml-5 mt-5">
          <b-field>
            <b-checkbox
              v-model="features_dup.todo_lists"
            >
              {{ $t('navbar.employee.project.todos') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox
              v-model="features_dup.documents"
            >
              {{ $t('navbar.employee.project.documents') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox
              v-model="features_dup.decisions"
            >
              {{ $t('navbar.employee.project.decisions') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox
              v-model="features_dup.announcements"
            >
              {{ $t('navbar.employee.project.news') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox
              v-model="features_dup.surveys"
            >
              {{ $t('navbar.employee.project.surveys') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox
              v-model="features_dup.question_lists"
            >
              {{ $t('navbar.employee.project.question_lists') }}
            </b-checkbox>
          </b-field>
        </section>
      </div>
    </template>
    <template v-if="current_step === 2" #content>
      <div class="columns">
        <div class="column is-offset-1 is-5 text-center">
          <div :class="[{ selected: project.attributes.skip_unit }, 'card', 'cursor-pointer', 'p-4', 'text-center', 'mb-3']" @click="toggleProjectType(true)">
            <p class="card-header-title justify-center">
              {{ $t('project.labels.singleUnit') }}
            </p>
          </div>
          <p class="unit-explanation">
            {{ $t('project.tooltip.singleUnit') }}
          </p>
        </div>
        <div class="column is-5 text-center">
          <div :class="[{ selected: !project.attributes.skip_unit }, 'card', 'cursor-pointer', 'p-4', 'text-center', 'mb-3']" @click="toggleProjectType(false)">
            <p class="card-header-title justify-center">
              {{ $t('project.labels.multiUnits') }}
            </p>
          </div>
          <p class="unit-explanation">
            {{ $t('project.tooltip.multiUnits') }}
          </p>
        </div>
      </div>
    </template>
    <template v-if="current_step === 3" #content>
      <section class="section mb-4" style="margin-top: -1rem;">
        <div class="columns">
          <div
            v-for="(level_option) in level_options"
            :key="level_option.key"
            class="column is-one-fifth ">
            <div
              class="box outline outline-slate-300 h-64 relative w-full"
              :class="{ 'has-background-white-ter': level_option.disabled, 'cursor-pointer': !level_option.disabled }"
              @click="toggleOption(level_option.attribute)">
              <b-checkbox
                class="w-full"
                :disabled="level_option.disabled"
                :modelValue="level_option.value">
                <span class="title is-5">
                  {{ $truncate(level_option.label, 16) }}
                </span>
              </b-checkbox>
              <p class="mt-2">
                <FontAwesomeIcon class="mr-2 has-text-grey" :icon="level_option.icon"/>
                {{ level_option.explainer }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </template>
    <template #ButtonPrimary>
      {{ primaryButtonText }}
    </template>
    <template v-if="current_step > 1" #ButtonSecondary>
      {{ $t('modal.actions.goBack') }}
    </template>
  </Modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import Modal from '@/app/shared_components/modals/TheModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import SelectProject from '@/app/shared_components/selects/SelectProject.vue';
import { PROJECT_TYPES } from '@/app/data/model_constants';

export default {
  name: 'ProjectModal',

  components: { SelectProject, Modal },

  setup() { return { v$: useVuelidate() }; },

  data() {
    return {
      current_step: 1,
      project: {
        attributes: {
          name: '',
          skip_phase: true,
          skip_lot: true,
          skip_building: true,
          skip_unit: true,
          skip_customer: false,
          status: 'enabled',
        },
      },
      project_temp_id: null,
      features_dup: {
        todo_lists: true,
        documents: true,
        decisions: true,
        surveys: true,
        question_lists: true,
        announcements: true
      },
    };
  },

  validations: {
    project: {
      attributes: {
        name: {
          required,
          uniqueName(val) {
            return this.projects.length === 0
                || !_.find(this.projects, (project) => project.attributes.name.toLowerCase() === val.toLowerCase());
          },
        },
      },
    },
  },

  computed: {
    ...mapGetters(['current_client', 'projects']),

    projects_template() {
      // TODO: this needs pagination with backend filtering
      return _.chain(this.projects)
        .filter({ attributes: { skip_customer: true } })
        .orderBy(['attributes.name'], ['asc'])
        .value();
    },

    canAddSUPs() {
      return [PROJECT_TYPES.SUP.value, PROJECT_TYPES.SUP_AND_MUP.value].includes(this.current_client.attributes.project_type);
    },

    modalTitle() {
      if (this.current_step === 1) {
        return this.$t('project.labels.newProjectTitle')
      }
      if (this.current_step === 2) {
        return this.$t('project.labels.modalStep2Title');
      }
      return this.$t('project.labels.step3Title');
    },

    primaryButtonText() {
      if (this.project.attributes.skip_customer || this.project_temp_id || (this.current_step === 2 && this.project.attributes.skip_unit) || this.current_step === 3) {
        return this.$t('project.actions.submit');
      }

      return this.$t('project.actions.next');
    },

    level_options() {
      return {
        projects: {
          label: this.project.attributes.name,
          value: true,
          disabled: true,
          explainer: this.$t('project.labels.levelProjectExplainer')
        },
        phase: {
          attribute: 'skip_phase',
          label: this.$t('levels.phase'),
          value: !this.project.attributes.skip_phase,
          explainer: this.$t('project.labels.levelPhaseExplainer'),
          icon: 'calendar-days'
        },
        lot: {
          attribute: 'skip_lot',
          label: this.$t('levels.lot'),
          value: !this.project.attributes.skip_lot,
          explainer: this.$t('project.labels.levelPlotExplainer'),
          icon: 'chart-tree-map'
        },
        building: {
          attribute: 'skip_building',
          label: this.$t('levels.building'),
          value: !this.project.attributes.skip_building,
          explainer: this.$t('project.labels.levelBuildingExplainer'),
          icon: 'building'
        },
        units: {
          label: this.$t('levels.unit'),
          value: true,
          disabled: true,
          explainer: this.$t('project.labels.levelUnitExplainer'),
        },
      };
    },
  },

  methods: {
    ...mapActions([
      'CREATE_PROJECT',
      'DUPLICATE_PROJECT',
    ]),

    toggleProjectType(is_sup) {
      this.project.attributes.skip_unit = is_sup;
    },

    toggleProjectSkipSubLevels(v) {
      this.project.attributes.skip_phase = v;
      this.project.attributes.skip_lot = v;
      this.project.attributes.skip_building = v;
    },

    onSubmit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        if (this.project_temp_id || this.project.attributes.skip_customer || (this.current_step > 1 && this.project.attributes.skip_unit)) {
          this.createProject();
          return;
        }
        if (this.current_step === 1) {
          if (this.current_client.attributes.project_type === PROJECT_TYPES.SUP.value) {
            this.createProject()
            return;
          }
          if (this.current_client.attributes.project_type === PROJECT_TYPES.MUP.value) {
            this.project.attributes.skip_unit = false;
            this.current_step += 2;
            return;
          }
        }
        if (this.current_step === 2 && this.project.attributes.skip_unit) {
          this.createProject();
          return;
        }
        if (this.current_step === 3) {
          this.createProject();
          return;
        }
        this.current_step += 1;
      }
    },

    onBack() {
      if (this.current_step === 3 && this.current_client.attributes.project_type === PROJECT_TYPES.MUP.value) {
        // Step 3 is only available for MUPs. Skip project type selection if I can only have MUPs.
        this.current_step -= 2;
        return;
      }
      this.current_step -= 1
    },

    async createProject() {
      if (this.project.attributes.skip_unit) { // Ensure all sub levels are skipped if SUP
        this.toggleProjectSkipSubLevels(this.project.attributes.skip_unit)
      } else if (this.project.attributes.skip_customer || this.project_temp_id) { // Ensure project is SUP if it is template
        this.toggleProjectType(true)
        this.toggleProjectSkipSubLevels(this.project.attributes.skip_unit)
      }
      let project
      try {
        project = await this.CREATE_PROJECT({ record: this.project })
      } catch (e) {
        if (e.response.status === 422) {
          const errors = e.response.data.errors
          const errorKey = Object.keys(errors)[0]
          const errorMessage = errors[errorKey][0]
          this.toast_fail(this.$t(`project.errors.${errorKey}.${errorMessage}`));
        } else {
          this.toast_action_failed()
        }
      }
      if (this.project_temp_id) {
        await this.DUPLICATE_PROJECT({ record: { project_temp_id: this.project_temp_id, project_dup_id: project.id, features_dup: this.features_dup } });
      }
      this.$parent.$parent.close();
      if (project) {
        const route_name = this.project.attributes.skip_customer
          ? ROUTES_EMPLOYEES.PROJECT_TODO_LISTS
          : ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS;
        this.$router.push({
          name: route_name,
          params: { project_id: project.id, level_type: project.type, level_id: project.id }
        })
      }
    },

    toggleOption(attribute_name) {
      const attribute_depth = attribute_name.split('.');
      if (attribute_depth.length > 1) {
        // eslint-disable-next-line max-len
        this.project.attributes[attribute_depth[0]][attribute_depth[1]] = !this.project.attributes[attribute_depth[0]][attribute_depth[1]];
      } else {
        this.project.attributes[attribute_depth[0]] = !this.project.attributes[attribute_depth[0]];
      }
    },
  }
};
</script>

<style scoped lang="scss">
  .card {
    border-radius: 0.5rem;
    &.selected {
      border: 2px solid #1e2445;
      color: #1e2445;

      .card-header-title {
        color: #1e2445;
      }
    }
  }

  .unit-explanation {
    color: #363636;
  }
</style>
