// the constants must be a 1-1 mapping with the corresponding Ruby file

export const FT = {
  AFTERCARE: 'aftercare',
  AFTERCARE_CUSTOMER_FEEDBACK: 'aftercare_customer_feedback',
  AFTERCARE_CUSTOMER_ISSUE_CREATION: 'aftercare_customer_issue_creation',
  CLIENT_OVERVIEW: 'client_overview',
  CONVERSATION_MENTIONS: 'conversation_mentions',
  CUSTOMERS: 'customers',
  DECISIONS: 'decisions',
  DECISIONS_FINANCIALS_EXTENDED: 'decisions_financials_extended',
  DECISIONS_FULL: 'decisions_full',
  DOCUMENT_CATEGORIES: 'document_categories',
  EMAIL_FORWARDING_CLIENT: 'email_forwarding_client',
  EMAIL_FORWARDING_PROJECT: 'email_forwarding_project',
  EMPLOYEE_CUSTOM_THEME: 'employee_custom_theme',
  EMPLOYEE_IMPORT_CUSTOMERS: 'employee_import_customers',
  EMPLOYEE_IMPORT_SPACES: 'employee_import_spaces',
  EMPLOYEE_IMPORT_UNITS: 'employee_import_units',
  FINANCIALS: 'financials',
  HORIZONTAL_NAV: 'horizontal_nav',
  HORIZONTAL_NAV_FOR_USERS: 'horizontal_nav_for_users',
  MILESTONES: 'milestones',
  PROJECT_PARTNERS: 'project_partners',
  PROJECT_SUBDOMAINS: 'project_subdomains',
  PROPOSAL_SUBCHOICES: 'proposal_subchoices',
  PROPOSAL_TEMPLATES: 'proposal_templates',
  SALES: 'sales',
  SALES_PARTNERS: 'sales_partners',
  SIGNATORIES_MULTIPLE: 'signatories_multiple',
  TICKET_CATEGORIES: 'ticket_categories',
}

export const FEATURES_CLIENT = [
  FT.AFTERCARE,
  FT.AFTERCARE_CUSTOMER_FEEDBACK,
  FT.AFTERCARE_CUSTOMER_ISSUE_CREATION,
  FT.CLIENT_OVERVIEW,
  FT.CONVERSATION_MENTIONS,
  FT.CUSTOMERS,
  FT.DECISIONS,
  FT.DECISIONS_FINANCIALS_EXTENDED,
  FT.DECISIONS_FULL,
  FT.DOCUMENT_CATEGORIES,
  FT.EMAIL_FORWARDING_CLIENT,
  FT.EMAIL_FORWARDING_PROJECT,
  FT.EMPLOYEE_IMPORT_CUSTOMERS,
  FT.EMPLOYEE_CUSTOM_THEME,
  FT.EMPLOYEE_IMPORT_SPACES,
  FT.EMPLOYEE_IMPORT_UNITS,
  FT.FINANCIALS,
  FT.HORIZONTAL_NAV_FOR_USERS,
  FT.MILESTONES,
  FT.PROJECT_PARTNERS,
  FT.PROJECT_SUBDOMAINS,
  FT.PROPOSAL_SUBCHOICES,
  FT.PROPOSAL_TEMPLATES,
  FT.SALES,
  FT.SIGNATORIES_MULTIPLE,
  FT.TICKET_CATEGORIES,
]

export const FEATURES_EMPLOYEE = [
  FT.HORIZONTAL_NAV,
]

export const FEATURES_STARTER = [
  FT.DECISIONS,
  FT.EMPLOYEE_IMPORT_UNITS,
  FT.EMPLOYEE_IMPORT_SPACES,
  FT.EMPLOYEE_IMPORT_CUSTOMERS,
]

// TODO: sort these post production rollout (now it's easy to compare)
export const FEATURES_EXPERT = [
  FT.DECISIONS_FULL,
  FT.EMAIL_FORWARDING_CLIENT,
  FT.DOCUMENT_CATEGORIES,
  FT.AFTERCARE,
  FT.AFTERCARE_CUSTOMER_ISSUE_CREATION,
  FT.FINANCIALS,
  FT.PROJECT_PARTNERS,
  FT.PROPOSAL_SUBCHOICES,
  FT.PROPOSAL_TEMPLATES,
  FT.MILESTONES,
  FT.CONVERSATION_MENTIONS,
  FT.CUSTOMERS,
  FT.SALES,
]

export const FEATURES_ENTERPRISE = [
  FT.DECISIONS_FINANCIALS_EXTENDED,
  FT.EMAIL_FORWARDING_PROJECT,
  FT.PROJECT_SUBDOMAINS,
]

export const FEATURES_DEV = [
  FT.AFTERCARE_CUSTOMER_FEEDBACK,
  FT.CLIENT_OVERVIEW,
  FT.EMPLOYEE_CUSTOM_THEME,
  FT.HORIZONTAL_NAV_FOR_USERS,
  FT.SALES,
  FT.SALES_PARTNERS,
  FT.TICKET_CATEGORIES,
]
